<template>
  <div>
    <div class="vx-col w-full sm:w-1/1 lg:w-1/1 mb-base">
      <b-card class="mt-1">
        <div class="row">
          <div class="col-md-4 vx">
            <label for="type" style="font-size: 20px"> Select Type :</label>
            <v-select
              label="name"
              v-model="selectType"
              :options="typeOptions"
              @input="setType"
            />
          </div>
          <div class="col-md-4" v-if="selectType && selectType.name !== 'Approvals'">
            <label for="type" style="font-size: 20px"> Whom :</label>
            <v-select
              label="name"
              v-model="selectWhom"
              :options="whomOptions"
              @input="setWhom"
            />
          </div>
          <div class="col-md-4" v-if="showDepartments">
            <label for="departments" style="font-size: 20px"> {{ whomValue }} :</label>
            <v-select
              label="name"
              v-model="selectusers"
              :options="departmentOptions"
              @input="setUsers($event)"
            />
          </div>
          <div class="col-md-4" v-if="showPositions">
            <label for="positions" style="font-size: 20px"> {{ whomValue }} :</label>
            <v-select label="name" :options="positionOptions" @input="setUsers($event)" />
          </div>
          <div class="col-md-4" v-if="showUsers">
            <label for="users" style="font-size: 20px"> {{ whomValue }} :</label>
            <v-select label="fullname" :options="userOptions" @input="setUsers($event)">
              <template #option="{ name, profile_image, username, fullname, position }">
                <b-avatar :src="getprofileImage(profile_image)" v-if="name !== 'All'" />
                <span class="font-weight-bolder">
                  {{ fullname }}
                  {{ name !== "All" ? "/" + (position ? position.name : "") : "" }}</span
                >
                <span v-if="name !== 'All'" class="font-weight-bolder"
                  >/{{ username }}</span
                >
              </template>
            </v-select>
          </div>
        </div>
      </b-card>
      <b-card class="overflow-auto">
        <div class="mt-2" v-if="typeValue == 'Modules'" style="min-width: 1600px">
          <!-- <b-card v-if="showRights"> -->
          <div class="accordion mt-1" role="tablist">
            <b-table class="mb-0" small no-border-collapse :fields="field"> </b-table>

            <div class="sticky-header">
              <app-collapse>
                <app-collapse-item
                  v-for="(item, index) in softwareList"
                  :key="index"
                  title=""
                >
                  <div slot="header">{{ item.name }}</div>
                  <table
                    style="width: 100%"
                    v-for="subitem in filterModule(item.id)"
                    :key="subitem.id"
                  >
                    <tbody>
                      <td style="width: 3%; text-align: left">
                        <!-- <input type="checkbox" :v-model="selectall" :checked="subitem.all" /> -->
                        <input
                          type="checkbox"
                          @change="handleChangeCheckBoxSelectAll($event, subitem)"
                          :checked="checkedAll"
                        />
                      </td>
                      <td style="width: 3%; text-align: center">
                        <!-- {{ srno + 1 }} -->
                      </td>
                      <td style="width: 10%" class="text-left">
                        {{ subitem.name }}
                      </td>
                      <td style="width: 6%; text-align: center">
                        <input
                          type="checkbox"
                          @change="handleCheckBoxChange($event, subitem.id, 'add')"
                          :v-model="
                            userRights[subitem.id] && userRights[subitem.id]['add']
                              ? userRights[subitem.id]['add']
                              : 0
                          "
                          :checked="
                            userRights[subitem.id] && userRights[subitem.id]['add']
                              ? userRights[subitem.id]['add']
                              : 0
                          "
                        />
                      </td>
                      <td style="width: 6%; text-align: center">
                        <input
                          type="checkbox"
                          @change="handleCheckBoxChange($event, subitem.id, 'edit')"
                          :v-model="
                            userRights[subitem.id] && userRights[subitem.id]['edit']
                              ? userRights[subitem.id]['edit']
                              : 0
                          "
                          :checked="
                            userRights[subitem.id] && userRights[subitem.id]['edit']
                              ? userRights[subitem.id]['edit']
                              : 0
                          "
                        />
                      </td>
                      <td style="width: 6%; text-align: center">
                        <input
                          type="checkbox"
                          @change="handleCheckBoxChange($event, subitem.id, 'delete')"
                          :v-model="
                            userRights[subitem.id] && userRights[subitem.id]['delete']
                              ? userRights[subitem.id]['delete']
                              : 0
                          "
                          :checked="
                            userRights[subitem.id] && userRights[subitem.id]['delete']
                              ? userRights[subitem.id]['delete']
                              : 0
                          "
                        />
                      </td>
                      <td style="width: 6%; text-align: center">
                        <input
                          type="checkbox"
                          @change="handleCheckBoxChange($event, subitem.id, 'view')"
                          :v-model="
                            userRights[subitem.id] && userRights[subitem.id]['view']
                              ? userRights[subitem.id]['view']
                              : 0
                          "
                          :checked="
                            userRights[subitem.id] && userRights[subitem.id]['view']
                              ? userRights[subitem.id]['view']
                              : 0
                          "
                        />
                      </td>
                      <td style="width: 6%; text-align: center">
                        <input
                          type="checkbox"
                          @change="handleCheckBoxChange($event, subitem.id, 'all_data')"
                          :v-model="
                            userRights[subitem.id] && userRights[subitem.id]['all_data']
                              ? userRights[subitem.id]['all_data']
                              : 0
                          "
                          :checked="
                            userRights[subitem.id] && userRights[subitem.id]['all_data']
                              ? userRights[subitem.id]['all_data']
                              : 0
                          "
                        />
                      </td>
                      <td style="width: 6%; text-align: center">
                        <input
                          type="checkbox"
                          @change="handleCheckBoxChange($event, subitem.id, 'self_data')"
                          :v-model="
                            userRights[subitem.id] && userRights[subitem.id]['self_data']
                              ? userRights[subitem.id]['self_data']
                              : 0
                          "
                          :checked="
                            userRights[subitem.id] && userRights[subitem.id]['self_data']
                              ? userRights[subitem.id]['self_data']
                              : 0
                          "
                        />
                      </td>
                      <td style="width: 6%; text-align: center">
                        <input
                          type="checkbox"
                          @change="
                            handleCheckBoxChange($event, subitem.id, 'self_child_data')
                          "
                          :v-model="
                            userRights[subitem.id] &&
                            userRights[subitem.id]['self_child_data']
                              ? userRights[subitem.id]['self_child_data']
                              : 0
                          "
                          :checked="
                            userRights[subitem.id] &&
                            userRights[subitem.id]['self_child_data']
                              ? userRights[subitem.id]['self_child_data']
                              : 0
                          "
                        />
                      </td>
                      <td style="width: 6%; text-align: center">
                        <input
                          type="checkbox"
                          @change="handleCheckBoxChange($event, subitem.id, 'email')"
                          :v-model="
                            userRights[subitem.id] && userRights[subitem.id]['email']
                              ? userRights[subitem.id]['email']
                              : 0
                          "
                          :checked="
                            userRights[subitem.id] && userRights[subitem.id]['email']
                              ? userRights[subitem.id]['email']
                              : 0
                          "
                        />
                      </td>
                      <td style="width: 6%; text-align: center">
                        <input
                          type="checkbox"
                          @change="handleCheckBoxChange($event, subitem.id, 'pdf')"
                          :v-model="
                            userRights[subitem.id] && userRights[subitem.id]['pdf']
                              ? userRights[subitem.id]['pdf']
                              : 0
                          "
                          :checked="
                            userRights[subitem.id] && userRights[subitem.id]['pdf']
                              ? userRights[subitem.id]['pdf']
                              : 0
                          "
                        />
                      </td>
                      <td style="width: 6%; text-align: center">
                        <input
                          type="checkbox"
                          @change="handleCheckBoxChange($event, subitem.id, 'excel')"
                          :v-model="
                            userRights[subitem.id] && userRights[subitem.id]['excel']
                              ? userRights[subitem.id]['excel']
                              : 0
                          "
                          :checked="
                            userRights[subitem.id] && userRights[subitem.id]['excel']
                              ? userRights[subitem.id]['excel']
                              : 0
                          "
                        />
                      </td>
                      <td style="width: 6%; text-align: center">
                        <input
                          type="checkbox"
                          @change="handleCheckBoxChange($event, subitem.id, 'print')"
                          :v-model="
                            userRights[subitem.id] && userRights[subitem.id]['print']
                              ? userRights[subitem.id]['print']
                              : 0
                          "
                          :checked="
                            userRights[subitem.id] && userRights[subitem.id]['print']
                              ? userRights[subitem.id]['print']
                              : 0
                          "
                        />
                      </td>
                      <td style="width: 6%; text-align: center">
                        <input
                          type="checkbox"
                          @change="handleCheckBoxChange($event, subitem.id, 'audit')"
                          :v-model="
                            userRights[subitem.id] && userRights[subitem.id]['audit']
                              ? userRights[subitem.id]['audit']
                              : 0
                          "
                          :checked="
                            userRights[subitem.id] && userRights[subitem.id]['audit']
                              ? userRights[subitem.id]['audit']
                              : 0
                          "
                        />
                      </td>
                      <td style="width: 6%; text-align: center">
                        <input
                          type="checkbox"
                          @change="handleCheckBoxChange($event, subitem.id, 'sms')"
                          :v-model="
                            userRights[subitem.id] && userRights[subitem.id]['sms']
                              ? userRights[subitem.id]['sms']
                              : 0
                          "
                          :checked="
                            userRights[subitem.id] && userRights[subitem.id]['sms']
                              ? userRights[subitem.id]['sms']
                              : 0
                          "
                        />
                      </td>
                      <td style="width: 6%; text-align: center">
                        <input
                          type="checkbox"
                          @change="handleCheckBoxChange($event, subitem.id, 'whatsapp')"
                          :v-model="
                            userRights[subitem.id] && userRights[subitem.id]['whatsapp']
                              ? userRights[subitem.id]['whatsapp']
                              : 0
                          "
                          :checked="
                            userRights[subitem.id] && userRights[subitem.id]['whatsapp']
                              ? userRights[subitem.id]['whatsapp']
                              : 0
                          "
                        />
                      </td>
                      <td style="width: 6%; text-align: center">
                        <input
                          type="checkbox"
                          @change="handleCheckBoxChange($event, subitem.id, 'lock')"
                          :v-model="
                            userRights[subitem.id] && userRights[subitem.id]['lock']
                              ? userRights[subitem.id]['lock']
                              : 0
                          "
                          :checked="
                            userRights[subitem.id] && userRights[subitem.id]['lock']
                              ? userRights[subitem.id]['lock']
                              : 0
                          "
                        />
                      </td>
                    </tbody>
                  </table>
                </app-collapse-item>
              </app-collapse>
            </div>
          </div>
        </div>
        <div class="mt-2" v-if="typeValue == 'Widgets'">
          <div class="accordion mt-1" role="tablist">
            <table class="table table-bordered">
              <thead class="text">
                <tr>
                  <th>Modules</th>
                  <th>View</th>
                </tr>
              </thead>
            </table>

            <div>
              <app-collapse>
                <app-collapse-item
                  v-for="(item, index) in widgetRights"
                  :key="index"
                  title=""
                >
                  <div slot="header">{{ index }}</div>
                  <table
                    style="width: 100%"
                    class="table"
                    v-for="subitem in item"
                    :key="subitem.id"
                  >
                    <tbody>
                      <td class="text-left">
                        {{ subitem.name }}
                      </td>
                      <td style="width: 10%; text-align: center">
                        <input type="checkbox" v-model="subitem.view" />
                      </td>
                    </tbody>
                  </table>
                </app-collapse-item>
              </app-collapse>
            </div>
          </div>
        </div>

        <div class="mt-2 overflow-auto" v-if="typeValue == 'Approvals' && showApprovals">
          <table class="table table-bordered" style="min-width: 45cm">
            <thead class="text">
              <tr style="width: 100%">
                <th>Sr.No.</th>
                <th>Software</th>
                <th>Modules</th>
                <th>Approval Name</th>
                <th>Level1 Type</th>
                <th style="width: 8cm">Level1</th>
                <th>Level2 Type</th>
                <th style="width: 8cm">Level2</th>
                <th>Level3 Type</th>
                <th style="width: 8cm">Level3</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in approvals" :key="index">
                <td>
                  <label>{{ index + 1 }} </label>
                </td>
                <td>
                  <label>{{ item.software ? item.software.name : "" }}</label>
                </td>
                <td>
                  <label>{{ item.module ? item.module.name : "" }}</label>
                </td>
                <td>{{ item.name }}</td>
                <td>
                  <v-select
                    v-model="userApprovals[item.id]['level1type']"
                    @input="handelclick(item.id, $event, 'level1type')"
                    placeholder="None"
                    label="name"
                    :options="level"
                  />
                </td>

                <td style="max-width: 4cm">
                  <v-select
                    v-if="showlevel1[item.id]"
                    v-model="userApprovals[item.id]['level1']"
                    @input="handelclick(item.id, $event, 'level1')"
                    label="name"
                    :options="userOptions"
                    multiple
                  >
                    <template
                      #option="{ name, profile_image, username, surname, position }"
                    >
                      <b-avatar :src="getprofileImage(profile_image)" />
                      <span class="font-weight-bolder">
                        {{ name }} {{ surname }}
                        {{ "/" + (position ? position.name : "") }}</span
                      >
                      <span class="font-weight-bolder">/{{ username }}</span>
                    </template>
                  </v-select>
                </td>
                <td>
                  <v-select
                    v-model="userApprovals[item.id]['level2type']"
                    @input="handelclick(item.id, $event, 'level2type')"
                    placeholder="None"
                    label="name"
                    :options="level"
                  />
                </td>
                <td style="max-width: 4cm">
                  <v-select
                    v-if="showlevel2[item.id]"
                    v-model="userApprovals[item.id]['level2']"
                    @input="handelclick(item.id, $event, 'level2')"
                    label="name"
                    :options="userOptions"
                    multiple
                  >
                    <template
                      #option="{ name, profile_image, username, surname, position }"
                    >
                      <b-avatar :src="getprofileImage(profile_image)" />
                      <span class="font-weight-bolder">
                        {{ name }} {{ surname }}
                        {{ "/" + (position ? position.name : "") }}</span
                      >
                      <span class="font-weight-bolder">/{{ username }}</span>
                    </template>
                  </v-select>
                </td>
                <td>
                  <v-select
                    :v-model="userApprovals[item.id]['level3type']"
                    @input="handelclick(item.id, $event, 'level3type')"
                    placeholder="None"
                    label="name"
                    :options="level"
                  />
                </td>
                <td style="max-width: 4cm">
                  <v-select
                    v-if="showlevel3[item.id]"
                    v-model="userApprovals[item.id]['level3']"
                    @input="handelclick(item.id, $event, 'level3')"
                    label="name"
                    :options="userOptions"
                    multiple
                  >
                    <template
                      #option="{ name, profile_image, username, surname, position }"
                    >
                      <b-avatar :src="getprofileImage(profile_image)" />
                      <span class="font-weight-bolder">
                        {{ name }} {{ surname }}
                        {{ "/" + (position ? position.name : "") }}</span
                      >
                      <span class="font-weight-bolder">/{{ username }}</span>
                    </template>
                  </v-select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="mt-2" v-if="typeValue == 'Reminders'">
          <table class="table table-bordered">
            <thead class="text">
              <tr>
                <th>Sr.No.</th>
                <th>type</th>
                <th>Approval Name</th>
                <th>Level1 Type</th>
                <th>Level1</th>
                <th>Level2 Type</th>
                <th>Level2</th>
                <th>Level3 Type</th>
                <th>Level3</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in reminders" :key="index">
                <td>
                  <label>{{ index + 1 }} </label>
                </td>
                <td>
                  <label>{{ item.modules }}</label>
                </td>
                <td>{{ item.name }}</td>
                <td>
                  <!-- <label>{{ userApprovals }}</label> -->

                  <v-select
                    :v-model="
                      userReminders[item.id] && userReminders[item.id]['level1']
                        ? userReminders[item.id]['level1']
                        : 0
                    "
                    @input="handleClickReminder(item.id, $event, 'level1')"
                    placeholder="None"
                    label="name"
                    :options="level"
                  />
                </td>

                <td>
                  <v-select
                    v-if="showlevel1[item.id]"
                    :v-model="
                      userReminders[item.id] && userReminders[item.id]['level1type']
                        ? userReminders[item.id]['level1type']
                        : 0
                    "
                    @input="handleClickReminder(item.id, $event, 'level1type')"
                    label="name"
                    :options="userOptions"
                    multiple
                  />
                </td>
                <td>
                  <v-select
                    :v-model="
                      userReminders[item.id] && userReminders[item.id]['level2']
                        ? userReminders[item.id]['level2']
                        : 0
                    "
                    @input="handleClickReminder(item.id, $event, 'level2')"
                    placeholder="None"
                    label="name"
                    :options="level"
                  />
                </td>
                <td>
                  <v-select
                    v-if="showlevel2[item.id]"
                    :v-model="
                      userReminders[item.id] && userReminders[item.id]['level2type']
                        ? userReminders[item.id]['level2type']
                        : 0
                    "
                    @input="handleClickReminder(item.id, $event, 'level2type')"
                    label="name"
                    :options="userOptions"
                    multiple
                  />
                </td>
                <td>
                  <v-select
                    :v-model="
                      userReminders[item.id] && userReminders[item.id]['level3']
                        ? userReminders[item.id]['level3']
                        : 0
                    "
                    @input="handleClickReminder(item.id, $event, 'level3')"
                    placeholder="None"
                    label="name"
                    :options="level"
                  />
                </td>
                <td>
                  <v-select
                    v-if="showlevel3[item.id]"
                    :v-model="
                      userReminders[item.id] && userReminders[item.id]['level3type']
                        ? userReminders[item.id]['level3type']
                        : 0
                    "
                    @input="handleClickReminder(item.id, $event, 'level3type')"
                    label="name"
                    :options="userOptions"
                    multiple
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card>
    </div>
    <div class="pt-2 flex" style="justify-content: center">
      <vs-button
        @click="AssignClicked"
        class="shadow-lg rounded"
        color="primary"
        type="filled"
      >
        {{ typeValue == null ? "Assign Rights" : "" }}
        {{ typeValue == "Modules" ? "Assign Rights" : "" }}
        {{ typeValue == "Widgets" ? "Widget" : "" }}
        {{ typeValue == "Approvals" ? "Approval" : "" }}
        {{ typeValue == "Reminders" ? "Reminder" : "" }}
      </vs-button>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import Multiselect from "vue-multiselect";
import axios from "@/components/axios";
import { BTable, BCard, BAvatar } from "bootstrap-vue";
import GoodTableColumnSearch from "../../components/GoodTableColumnSearch.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
export default {
  components: {
    "v-select": vSelect,
    BTable,
    BCard,
    GoodTableColumnSearch,
    AppCollapse,
    Multiselect,
    AppCollapseItem,
    BAvatar,
  },

  data() {
    return {
      checkedAll: 0,
      userOptions: [],
      positionOptions: [],
      departmentOptions: [],
      teamOptions: [],
      level1: [],
      selectedItems: [],
      selectedWhom: {},
      selectedUser: {},
      whomOptions: [
        { value: "Department", name: "Department" },
        { value: "Position", name: "Position" },
        { value: "User", name: "User" },
      ],
      moduleList: [],
      softwareList: [],
      userRights: [],
      userApprovals: [],
      userReminders: [],
      widgets: null,
      approvals: null,
      reminders: null,
      typeValue: null,
      whomValue: null,
      selectType: null,
      selectWhom: null,
      selectusers: null,
      value: "",
      userValue: null,
      eid: null,
      showUsers: false,
      showDepartments: false,
      showPositions: false,
      showRights: false,
      showApprovals: false,

      typeOptions: [
        { value: "Modules", name: "Modules" },
        { value: "Widgets", name: "Widgets" },
        { value: "Approvals", name: "Approvals" },
        { value: "Reminders", name: "Reminders" },
      ],
      level: [{ name: "None" }, { name: "User" }, { name: "Parent" }],
      field: [
        {
          key: "HR",
          label: "Select All",
          thStyle: {
            width: "6%",
            textAlign: "center",
            background: "transparent",
          },
        },
        {
          key: "Modules",
          label: "Modules",
          thStyle: {
            width: "10%",
            textAlign: "center",
            background: "transparent",
          },
        },
        {
          key: "Add",
          label: "Add",
          thStyle: {
            width: "6%",
            textAlign: "center",
            background: "transparent",
          },
        },
        {
          key: "Edit",
          label: "Edit",
          thStyle: {
            width: "6%",
            textAlign: "center",
            background: "transparent",
          },
        },
        {
          key: "Delete",
          label: "Delete",
          thStyle: {
            width: "6%",
            textAlign: "center",
            background: "transparent",
          },
        },
        {
          key: "View",
          label: "View",
          thStyle: {
            width: "6%",
            textAlign: "center",
            background: "transparent",
          },
        },
        {
          key: "all_data",
          label: "All Data",
          thStyle: {
            width: "6%",
            textAlign: "center",
            background: "transparent",
          },
        },
        {
          key: "self_data",
          label: "Self Data",
          thStyle: {
            width: "6%",
            textAlign: "center",
            background: "transparent",
          },
        },
        {
          key: "self_child_data",
          label: "Self & Child Data",
          thStyle: {
            width: "6%",
            textAlign: "center",
            background: "transparent",
          },
        },
        {
          key: "Email",
          label: "Email",
          thStyle: {
            width: "6%",
            textAlign: "center",
            background: "transparent",
          },
        },
        {
          key: "ExportToPDF",
          label: "Export To PDF",
          thStyle: {
            width: "6%",
            textAlign: "center",
            background: "transparent",
          },
        },
        {
          key: "ExportToExcel",
          label: "Export To Excel",
          thStyle: {
            width: "6%",
            textAlign: "center",
            background: "transparent",
          },
        },

        {
          key: "Print",
          label: "Print",
          thStyle: {
            width: "6%",
            textAlign: "center",
            background: "transparent",
          },
        },
        {
          key: "Audit",
          label: "Audit",
          thStyle: {
            width: "6%",
            textAlign: "center",
            background: "transparent",
          },
        },
        {
          key: "SMS",
          label: "SMS",
          thStyle: {
            width: "6%",
            textAlign: "center",
            background: "transparent",
          },
        },
        {
          key: "Whatsapp",
          label: "Whatsapp",
          thStyle: {
            width: "6%",
            textAlign: "center",
            background: "transparent",
          },
        },
        {
          key: "Lock",
          label: "Lock",
          thStyle: {
            width: "6%",
            textAlign: "center",
            background: "transparent",
          },
        },
      ],
      showlevel1: {},
      showlevel2: {},
      showlevel3: {},
      checkeditems: [],
      assignRightsData: [],
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      widgetRights: {},
    };
  },

  mounted() {
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      this.$router.push("/");
    }
    this.getUsersdata();
  },
  methods: {
    handelclick(id, e, name) {
      if (e) {
        if (name == "level1type" || name == "level2type" || name == "level3type") {
          this.userApprovals[id][`${name}`] = e;
          if (this.userApprovals[id][`${name}`].name == "User") {
            if (name == "level1type") {
              this.showlevel1[id] = true;
            } else if (name == "level2type") {
              this.showlevel2[id] = true;
            } else if (name == "level3type") {
              this.showlevel3[id] = true;
            }
          } else if (name == "level1type") {
            this.showlevel1[id] = false;
          } else if (name == "level2type") {
            this.showlevel2[id] = false;
          } else if (name == "level3type") {
            this.showlevel3[id] = false;
          }
        }
        if (name == "level1" || name == "level2" || name == "level3") {
          this.userApprovals[id][`${name}`] = e;
        }

        this.$forceUpdate();
      }
    },
    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath) ? image : this.imagePath + image;
        return test;
      }
      return image;
    },
    handleClickReminder(id, e, name) {
      if (name == "level1type" || name == "level2type" || name == "level3type") {
        this.userReminders[id][`${name}`] = e;
      }
      if (name == "level1" || name == "level2" || name == "level3") {
        this.userReminders[id][`${name}`] = e.name;
        if (this.userReminders[id][`${name}`] == "User") {
          if (name == "level1") {
            this.showlevel1[id] = true;
          } else if (name == "level2") {
            this.showlevel2[id] = true;
          } else if (name == "level3") {
            this.showlevel3[id] = true;
          }
        } else if (name == "level1") {
          this.userReminders[id][`${name}`] = null;
          this.showlevel1[id] = false;
        } else if (name == "level2") {
          this.userReminders[id][`${name}`] = null;
          this.showlevel2[id] = false;
        } else if (name == "level3") {
          this.userReminders[id][`${name}`] = null;
          this.showlevel3[id] = false;
        }
      }
      this.$forceUpdate();
    },
    filterModule(id) {
      return this.moduleList.filter((item) => item.sid == id);
    },
    checkbox(e, key, id) {},
    onSelect(value) {
      this.selectedItems.push(value[0].name);
      this.value = [];
    },
    handleChangeCheckBoxSelectAll(e, subitem) {
      if (subitem && subitem.id) {
        this.userRights[subitem.id]["add"] = e.target.checked ? 1 : 0;
        this.userRights[subitem.id]["edit"] = e.target.checked ? 1 : 0;
        this.userRights[subitem.id]["delete"] = e.target.checked ? 1 : 0;
        this.userRights[subitem.id]["all_data"] = e.target.checked ? 1 : 0;
        this.userRights[subitem.id]["self_data"] = e.target.checked ? 1 : 0;
        this.userRights[subitem.id]["self_child_data"] = e.target.checked ? 1 : 0;
        this.userRights[subitem.id]["pdf"] = e.target.checked ? 1 : 0;
        this.userRights[subitem.id]["print"] = e.target.checked ? 1 : 0;
        this.userRights[subitem.id]["email"] = e.target.checked ? 1 : 0;
        this.userRights[subitem.id]["sms"] = e.target.checked ? 1 : 0;
        this.userRights[subitem.id]["whatsapp"] = e.target.checked ? 1 : 0;
        this.userRights[subitem.id]["view"] = e.target.checked ? 1 : 0;
        this.userRights[subitem.id]["lock"] = e.target.checked ? 1 : 0;
        this.userRights[subitem.id]["audit"] = e.target.checked ? 1 : 0;
        this.userRights[subitem.id]["excel"] = e.target.checked ? 1 : 0;
      }
      this.$forceUpdate();
    },
    handleCheckBoxChange(e, id, action) {
      this.userRights[id][action] = e.target.checked ? 1 : 0;
      this.$forceUpdate();
    },
    async setType(value) {
      this.checkedAll = 0;
      this.typeValue = value == null ? null : value.name;
      // if (this.typeValue && this.whomValue !== null) {
      //   this.showUsers = true;
      // }
      // const user = {
      //   method: "GET",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${this.accessToken}`,
      //   },
      //   url: `${this.baseApi}/getUsers`,
      // };
      // axios(user)
      //   .then((response) => {
      //     this.userOptions = [];
      //     console.log(response);
      //     this.userOptions = response.data.data;
      //     response.data.data.map((item) => {
      //       this.userOptions.push({ name: item.name, value: item.id });
      //     });
      //   })
      //   .catch((error) => console.log(error, "error"));

      if (this.typeValue == "Modules") {
        const softwares = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/getsoftwares`,
        };

        axios(softwares)
          .then((response) => {
            this.softwareList = response.data.data;
            this.$forceUpdate();
          })
          .catch((error) => console.log(error, "error"));
        const module = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/getmodules`,
        };
        //         var groupBy = function(xs, key) {
        //   return xs.reduce(function(rv, x) {
        //     (rv[x[key]] = rv[x[key]] || []).push(x);
        //     return rv;
        //   }, {});
        // };

        axios(module)
          .then((response) => {
            this.moduleList = response.data.data;
            this.$forceUpdate();
          })
          .catch((error) => console.log(error, "error"));
      }
      // eslint-disable-next-line eqeqeq
      if (this.typeValue == "Approvals") {
        const user = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/getapprovals`,
        };
        axios(user)
          .then((response) => {
            this.userApprovals = {};
            this.approvals = response.data.data;
            this.approvals.map((item, index) => {
              if (!this.userApprovals[item.id]) {
                this.userApprovals[item.id] = {};
              }
              item.id
                ? (this.userApprovals[item.id]["id"] = item.id)
                : (this.userApprovals[item.id]["id"] = null);
              item.name
                ? (this.userApprovals[item.id]["name"] = item.name)
                : (this.userApprovals[item.id]["name"] = null);
              item.level1
                ? (this.userApprovals[item.id]["level1"] = item.level1Users.filter(
                    (item) => item
                  ))
                : (this.userApprovals[item.id]["level1"] = []);
              item.level1type
                ? (this.userApprovals[item.id]["level1type"] = { name: item.level1type })
                : (this.userApprovals[item.id]["level1type"] = {});
              item.level2
                ? (this.userApprovals[item.id]["level2"] = item.level2Users.filter(
                    (item) => item
                  ))
                : (this.userApprovals[item.id]["level2"] = []);
              item.level2type
                ? (this.userApprovals[item.id]["level2type"] = { name: item.level2type })
                : (this.userApprovals[item.id]["level2type"] = {});
              item.level3
                ? (this.userApprovals[item.id]["level3"] = item.level3Users.filter(
                    (item) => item
                  ))
                : (this.userApprovals[item.id]["level3"] = []);
              item.level3type
                ? (this.userApprovals[item.id]["level3type"] = { name: item.level3type })
                : (this.userApprovals[item.id]["level3type"] = {});

              if (item.level1type == "User") {
                this.showlevel1[item.id] = true;
              }
              if (item.level2type == "User") {
                this.showlevel2[item.id] = true;
              }
              if (item.level3type == "User") {
                this.showlevel3[item.id] = true;
              }
            });
            this.$forceUpdate();
            this.showApprovals = true;
          })
          .catch((error) => console.log(error, "error"));
      }
      if (this.typeValue == "Reminders") {
        const user = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/getreminder`,
        };
        axios(user)
          .then((response) => {
            this.reminders = response.data.data;
            this.reminders.map((item) => {
              if (!this.userReminders[item.id]) {
                this.userReminders[item.id] = {};
              }
              item.id
                ? (this.userReminders[item.id]["id"] = item.id)
                : (this.userReminders[item.id]["id"] = null);
              item.SoftwareName
                ? (this.userReminders[item.id]["SoftwareName"] = item.SoftwareName)
                : (this.userReminders[item.id]["SoftwareName"] = null);
              item.modulesName
                ? (this.userReminders[item.id]["modulesName"] = item.modulesName)
                : (this.userReminders[item.id]["modulesName"] = null);
              item.name
                ? (this.userReminders[item.id]["name"] = item.name)
                : (this.userReminders[item.id]["name"] = null);
              item.level1
                ? (this.userReminders[item.id]["level1"] = item.level1)
                : (this.userReminders[item.id]["level1"] = null);
              item.level1type
                ? (this.userReminders[item.id]["level1type"] = item.level1type)
                : (this.userReminders[item.id]["level1type"] = []);
              item.level2
                ? (this.userReminders[item.id]["level2"] = item.level2)
                : (this.userReminders[item.id]["level2"] = null);
              item.level2type
                ? (this.userReminders[item.id]["level2type"] = item.level2type)
                : (this.userReminders[item.id]["level2type"] = []);
              item.level3
                ? (this.userReminders[item.id]["level3"] = item.level3)
                : (this.userReminders[item.id]["level3"] = null);
              item.level3type
                ? (this.userReminders[item.id]["level3type"] = item.level3type)
                : (this.userReminders[item.id]["level3type"] = []);
            });
            this.$forceUpdate();
          })
          .catch((error) => console.log(error, "error"));
      }
      if (this.typeValue == "Widgets") {
        const user = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/getwidgets`,
        };
        axios(user)
          .then((response) => {
            this.widgets = response.data.data;
            this.$forceUpdate();
          })
          .catch((error) => console.log(error, "error"));
      }
      // eslint-disable-next-line eqeqeq
      // if (this.typeValue == 'Reminders') {
      //   const user = {
      //     method: 'GET',
      //     headers: {
      //       'Content-Type': 'application/json',
      //       'Authorization': `Bearer ${this.accessToken}`
      //     },
      //     url: `${this.baseApi}/getreminder`
      //   }
      //   axios(user)
      //     .then((response) => {
      //       this.whomOptions = []
      //       console.log(response)
      //       this.reminders = response.data.data
      //       console.log(this.reminders, 'approvals==')
      //     })
      //     .catch((error) => console.log(error, 'error'))
      // }
    },
    async setWhom(value) {
      this.checkedAll = 0;
      (this.userRights = {}), (this.selectedWhom = value);
      this.whomValue = value.name;
      this.departmentOptions = [];
      this.positionOptions = [];
      if (this.whomValue == "User") {
        this.showUsers = true;
        this.showDepartments = false;
        this.showPositions = false;
        this.$forceUpdate();
      } else if (this.whomValue == "Position") {
        this.showPositions = true;
        this.showUsers = false;
        this.showDepartments = false;
        this.$forceUpdate();
        // eslint-disable-next-line eqeqeq
        const position = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/getPosition`,
        };
        await axios(position)
          .then((response) => {
            this.positionOptions = [];
            response.data.data.map((item) => {
              this.positionOptions.push({ name: item.name, value: item.id });
            });
          })
          .then(() => {
            this.$forceUpdate();
          })
          .catch((error) => console.log(error, "error"));
      } else if (this.whomValue == "Department") {
        this.showDepartments = true;
        this.showPositions = false;
        this.showUsers = false;
        this.$forceUpdate();
        // eslint-disable-next-line eqeqeq
        const department = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/getDepartment`,
        };
        await axios(department)
          .then((response) => {
            response.data.data.map((item) => {
              this.departmentOptions.push({ name: item.name, value: item.id });
            });
          })
          .then(() => {
            this.$forceUpdate();
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    async setUsers(value) {
      this.checkedAll = 0;
      (this.userRights = {}), (this.selectedUser = value);
      this.widgetRights = {};
      this.userValue = value?.name;
      this.eid = value?.value;
      this.showRights = true;
      if (value && this.selectType && this.selectType.name == "Modules") {
        this.getUserRights();
      } else if (value) {
        this.getWidgetRights();
      }
      this.$forceUpdate();
    },
    async getUserRights() {
      const userRes = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getuserrights`,
        data: {
          id: this.selectedUser.value,
          type: this.selectedWhom.value,
        },
      };
      await axios(userRes)
        .then((response) => {
          let result;
          if (typeof response.data.data == "object") {
            result = response.data.data;
          } else {
            result = JSON.parse(response.data.data);
          }
          // const result = JSON.parse(response.data.data)
          if (result) {
            const data = Object.keys(result).map((key) => result[key]);
            data.map((item) => {
              let index = this.moduleList.findIndex((inner) => inner.id == item.id);
              if (index !== -1) {
                this.userRights[item.moduleid] = item;
              }
            });
            this.moduleList.map((item) => {
              if (this.userRights[item.id] == undefined) {
                this.userRights[item.id] = {
                  action: null,
                  active: 1,
                  add: 0,
                  audit: 0,
                  branch: 1,
                  company: 1,
                  delete: 0,
                  deleted_at: null,
                  edit: 0,
                  eid: 11,
                  email: 0,
                  enterby: null,
                  enterdatetime: new Date(),
                  excel: 0,
                  id: item.id,
                  lock: 0,
                  modifiedby: 1,
                  modifieddatetime: new Date(),
                  moduleid: item.id,
                  modulename: item.name,
                  pdf: 0,
                  print: 0,
                  sms: 0,
                  timestamp: null,
                  view: 0,
                  whatsapp: 0,
                };
              }
              if (!this.userRights[item.id].modulename) {
                const data = {
                  modulename: item.name,
                };
                this.userRights[item.id] = {
                  ...this.userRights[item.id],
                  ...data,
                };
              }
            });
          } else {
            this.moduleList.map((item1) => {
              this.userRights[item1.id] = {
                action: null,
                active: 1,
                add: 0,
                audit: 0,
                branch: 1,
                company: 1,
                delete: 0,
                deleted_at: null,
                edit: 0,
                eid: 11,
                email: 0,
                enterby: null,
                enterdatetime: new Date(),
                excel: 0,
                id: item1.id,
                lock: 0,
                modifiedby: 1,
                modifieddatetime: new Date(),
                moduleid: item1.id,
                modulename: item1.name,
                pdf: 0,
                print: 0,
                sms: 0,
                timestamp: null,
                view: 0,
                whatsapp: 0,
              };
            });
          }
          this.$forceUpdate();
        })
        .catch((error) => console.log(error, "error"));
    },
    async getWidgetRights() {
      const userRes = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getwidgetrights`,
        data: {
          id: this.selectedUser.value,
          type: this.selectedWhom.value,
        },
      };
      await axios(userRes).then((response) => {
        let data = response.data.data;
        this.widgetRights = JSON.parse(JSON.stringify(this.widgets));
        if (data && this.widgetRights) {
          data = JSON.parse(data);
          for (let item in this.widgetRights) {
            this.widgetRights[item].map((inner) => {
              data.map((inner1) => {
                if (inner.id == inner1.id) {
                  inner.view = inner1.view;
                }
              });
            });
          }
        }
      });
      this.$forceUpdate();
    },
    getUsersdata() {
      const user = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getUsers`,
      };
      axios(user)
        .then((response) => {
          this.userOptions = [];
          response.data.data.map((item) => {
            item.value = item.id;
            this.userOptions.push(item);
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    AssignClicked() {
      if (this.typeValue == "Modules") {
        const id = 0;
        const assignRights = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/assignRights`,
          data: {
            id: this.eid,
            data: this.userRights,
            type: this.selectedWhom.value,
          },
        };
        axios(assignRights)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                variant: "success",
                text: `${response.data.message}`,
              },
            });

            this.selectType = null;
            this.selectWhom = null;
            this.selectusers = null;
            this.typeValue = null;
            // this.userOptions = [];
            this.showDepartments = false;
            this.showPositions = false;
            this.showUsers = false;
          })
          .catch((error) => console.log(error, "error"))
          .then(() => {});
      }
      if (this.typeValue == "Approvals") {
        let arr = Object.values(this.userApprovals);
        arr.map((item) => {
          item.level1 = item.level1 ? item.level1.map((item) => item.id).join(",") : "";
          item.level1type = item.level1type ? item.level1type.name : "";

          item.level2 = item.level2 ? item.level2.map((item) => item.id).join(",") : "";
          item.level2type = item.level2type ? item.level2type.name : "";

          item.level3 = item.level3 ? item.level3.map((item) => item.id).join(",") : "";
          item.level3type = item.level3type ? item.level3type.name : "";
        });
        const userApprovals = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/approvalrights`,
          data: arr,
        };
        axios(userApprovals)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                variant: "success",
                text: `${response.data.message}`,
              },
            });
          })
          .catch((error) => console.log(error, "error"));
      } else if (this.typeValue == "Reminders") {
        const userData = [];
        this.userReminders.map((item) => {
          userData.push(item);
        });

        const requestoption = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/reminderrights`,
          data: userData,
        };
        axios(requestoption)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                variant: "success",
                text: `${response.data.message}`,
              },
            });
          })
          .catch((error) => console.log(error, "error"));
      } else if (this.typeValue == "Widgets") {
        const userData = {
          type: this.selectedWhom.value,
          data: Object.values(this.widgetRights).flat(),
          id: this.eid,
        };
        const requestoption = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/assignWidgetRights`,
          data: userData,
        };
        axios(requestoption)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                variant: "success",
                text: `${response.data.message}`,
              },
            });
          })
          .catch((error) => console.log(error, "error"));
      }
    },
  },
};
</script>
<style lang="scss">
.table th {
  padding: 10px 0px !important;
}

.table td {
  padding: 10px !important;
}
.sticky-header {
  height: 300px;
  overflow: auto;
}

@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
